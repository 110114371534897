
import Vue from "vue";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";

export default Vue.extend({
    components: {
        MdsListGroup,
        MdsListGroupItem,
    },
});
