
import Vue from "vue";
import * as Config from "~/configs/markets";
import Movers from "@mca-components-ui/market-movers";

import MoversQuery from "~/services/queries/getMarketMovers.gql";
import type { Types as ApiTypes } from "@mca-dfd-api/graphql-schema";
import type {
    ITableRow,
    MarketMoversComponentStyle,
} from "@mca-components-ui/market-movers";

type MarketMoversResponse = {
  getMarketMovers: MarketMoversSecurities;
};

type MarketMoversSecurities = {
  securities: Array<ApiTypes.Security>;
};
export default Vue.extend({
    components: {
        Movers
    },
    props: {
        region: {
            type: String,
        },
        componentSize: {
            type: String,
        },
        tooltipText: {
            type: String,
        },
    },
    data() {
        return {
            selectedMarketId: "" as string,
            activeTab: "actives" as string,
            marketOptions: [] as Array<Config.SelectOption>,
            moversData: {} as ApiTypes.MarketMovers,
            tableRows: [] as ITableRow[],
            caption: "Markets Movers" as string,
        };
    },
    computed: {
        isMobileViewport(): boolean {
            return this.$store.state?.layout?.windowSize?.width <= 600 || false;
        },
        currentQuery(): any {
            if (this.activeTab === "gainers") {
                return MoversQuery.Gainers;
            }
            else if (this.activeTab === "losers") {
                return MoversQuery.Losers;
            }
            return MoversQuery.Actives;
        },
        marketId(): string {
            return (
                this.marketOptions.find((m) => m.selected === true)?.id ||
        this.marketOptions[0]?.id
            );
        },
        allMarketOptions(): Array<Config.SelectOption> {
            let rawList: Array<Config.SelectOption> = [];

            Config.MarketMoversRegions.forEach((val: Array<Config.SelectOption>) => {
                rawList = [...rawList, ...val];
            });
            return rawList;
        },
        tableHeight(): number | undefined {
            return this.isSmallComponent ? 150 : undefined;
        },
        isSmallComponent(): boolean {
            return this.componentSize?.toLowerCase() === "small";
        },
        showNetChangeColumn(): boolean {
            return !this.isSmallComponent;
        },
        componentStyle(): MarketMoversComponentStyle | undefined {
            return this.isSmallComponent ? "SMALL" : undefined;
        },
    },
    created() {
        this.marketOptions = this.region
            ? JSON.parse(JSON.stringify(Config.MarketMoversRegions.get(this.region)))
            : (this.allMarketOptions as Array<Config.SelectOption>);

        this.selectedMarketId = this.marketId;
    },
    watch: {
        region() {
            this.marketOptions = this.region
                ? JSON.parse(
                    JSON.stringify(Config.MarketMoversRegions.get(this.region))
                )
                : (this.allMarketOptions as Array<Config.SelectOption>);
            this.updateMarketSelection(this.marketId);
        },
    },
    async fetch() {
        await this.getMoversData();
    },
    methods: {
        updateMarketSelection(marketId: string) {
            this.selectedMarketId = marketId;
            this.$fetch();
        },
        onTabChange(tabName: string) {
            this.activeTab = tabName;
            this.$fetch();
        },
        async getMoversData() {
            try {
                const response = await this.$apollo.query<MarketMoversResponse>({
                    query: this.currentQuery,
                    variables: {
                        marketId: this.selectedMarketId,
                    }
                });

                this.tableRows = this.transformData(response.data);
            }
            catch (ex: any) {
                this.tableRows = [];
            }
        },
        transformData(movers: MarketMoversResponse) {
            return movers.getMarketMovers.securities?.map((element) => {
                return {
                    id: element.id,
                    name: element.name,
                    ticker: element.ticker,
                    exchangeCode: element.exchangeCode,
                    apir: element.apir,
                    symbol: element.ticker,
                    securityType: "ST",
                    price: element.intradayPrice?.price,
                    change: element.intradayPrice?.netChange,
                    changePercent: element.intradayPrice?.netChangePercent,
                } as ITableRow;
            });
        },
    },
});
