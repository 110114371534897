
import Vue from 'vue';
import type { MCA }  from '@mca-components-ui/types';
import { Content } from "@mca-components-ui/models";
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsIcon from '@mds/icon';
import { MdsCol } from "@mds/layout-grid";

export default Vue.extend({
    components: {
        MdsListGroup,
        MdsListGroupItem,
        MdsIcon,
        MdsCol
    },
    data() {
        return {
            isDataLoading: false,
            reposrtListRes: [] as MCA.Api.IDocument[],
            globalMarketInstrumentsCollection: {} as any,
        };
    },
    computed: {
        reportsList(): MCA.Models.IDocument[] {
            this.fetchReportsList();
            return this.reposrtListRes.map((document) => new Content.Document(document));
        },
        titleText(): string {
            return this.$store.state.contentstack.analystReports.title;
        },
        titleUrl(): string {
            return this.$store.state.contentstack.analystReports.redirectUrl ?? '';
        },
        isVisible(): boolean {
            return this.$store.state.contentstack.analystReports.isVisible;
        }
    },
    methods: {
        fetchReportsList() {
            this.reposrtListRes = this.$store.state.contentstack.analystReports.list;
        }
    },
    async fetch() {
        await this.$store.dispatch("contentstack/getAnalystReportsList");
    }

});
