
import Vue from 'vue';
import McaSpecialTopicColumn from '@mca-components-ui/special-topic-column';
import { Contentstack } from '@mca-components-ui/models';
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { PLACEHOLDERS } from "~/configs/images";

export default Vue.extend({
    components: {
        McaSpecialTopicColumn,
        MdsLayoutGrid, MdsRow, MdsCol
    },
    data() {
        return {
            PLACEHOLDERS
        };
    },
    computed: {
        specialTopics() {
            const topics =  this.$store.state.lineup.specialTopics;
            return [1,2,3].map(order => ({
                title: topics[`column_${order}_title`],
                url: topics[`column_${order}_url`] ?? '',
                stories: topics[`special_topic_column_${order}`]?.map((story: { [x: string]: any; }) => new Contentstack.TopStories(story[`special_topic_column_${order}_articles`]))
            }));
        },
    },
    async fetch () {
        await this.$store.dispatch('lineup/getSpecialTopicsFromCS');
    }
});
