
import Vue from 'vue';
import * as Config from '~/configs/markets';
import MdsIcon from '@mds/icon';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default Vue.extend({
    props: {
        frequency:{
            type: Number,
            default: 5
        },
        refreshTime:{
            type: Number,
            default: 300000
        }
    },
    components:{
        MdsIcon,
        VueSlickCarousel
    },
    data() {
        return {
            slickSettings: {
                arrows: true,
                draggable: true,
                infinite: false,
                slidesToShow: 4.25,
                slidesToScroll: 2.5,
                variableWidth: false,
                swipe: true,
                rows: 1,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3.25,
                            slidesToScroll: 2.5
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2.25,
                            slidesToScroll: 1,
                            arrows: false
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1.5,
                            slidesToScroll: 1,
                            arrows: false
                        }
                    },
                    {
                        breakpoint: 420,
                        settings: {
                            slidesToShow: 1.25,
                            slidesToScroll: 1,
                            arrows: false
                        }
                    }
                ]
            }
        };
    },
    computed: {        
        indicesOptions(): Array<Config.SelectOption> {
            return Config.GetSelectOptionsByIds(Config.MarketsIntradayCompareIds);
        },
        asOfTime(): string {
            const updateDate = this.$store.state.markets.marketsMovementUpdateTime;
            return updateDate ? this.$dateFns.format(updateDate, 'EEEE, dd MMMM yyyy hh:mmaaa') : '';
        }
    },
});
