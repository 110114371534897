
import Vue from 'vue';
import McaWebinars from '@mca-components-ui/webinars';
import type {MCA} from '@mca-components-ui/types';
import { Contentstack } from '@mca-components-ui/models';
import { PLACEHOLDERS } from '~/configs/images';

export default Vue.extend({
    components: {
        McaWebinars
    },
    data() {
        return {
            PLACEHOLDERS
        };
    },
    computed: {
        webinars(): MCA.Models.Contentstack.IWebinar[] {
            return this.$store.state.lineup.webinars.map((data: { webinar_block: any; })=> new Contentstack.Webinar(data.webinar_block));
        },
    },
    async fetch () {
        await this.$store.dispatch('lineup/getWebinarsFromCS');
    }
});
