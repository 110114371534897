import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c0db38c0&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=c0db38c0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0db38c0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarketIntradayMovement: require('/user/src/app/app/components/market/intraday-movement.vue').default,LineupTopStories: require('/user/src/app/app/components/lineup/TopStories.vue').default,MarketValuationChart: require('/user/src/app/app/components/market/valuation-chart.vue').default,MarketMovers: require('/user/src/app/app/components/market/movers.vue').default,MarketIntradayCompare: require('/user/src/app/app/components/market/intraday-compare.vue').default,MarketGlobal: require('/user/src/app/app/components/market/global.vue').default,HomepageRightColumn: require('/user/src/app/app/components/homepage/RightColumn.vue').default,LineupSpecialists: require('/user/src/app/app/components/lineup/Specialists.vue').default,LineupSpecialTopics: require('/user/src/app/app/components/lineup/SpecialTopics.vue').default,LineupLearnToInvest: require('/user/src/app/app/components/lineup/LearnToInvest.vue').default,LineupStandardCollections: require('/user/src/app/app/components/lineup/StandardCollections.vue').default,LineupAnalystReports: require('/user/src/app/app/components/lineup/AnalystReports.vue').default})
