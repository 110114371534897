
import Vue, { PropType } from "vue";
import { Location } from "vue-router";
import * as Config from "~/configs/markets";
import McaGlobalMarketsTable from "@mca-components-ui/global-markets-tables";
import type {
    ITableRow,
    MarketTablesComponentStyle,
} from "@mca-components-ui/global-markets-tables";
import * as Constants from "~/configs/page-layout";
import type { GlobalMarketData, MarketIndex, Commodity, ExchangeInfo } from "~/types/markets/globalMarket";

export default Vue.extend({
    components: {
        McaGlobalMarketsTable,
    },
    props: {
        size: {
            type: String,
            default: "small",
        },
        caption: {
            type: String,
        },
        headers: {
            type: [] as PropType<string[]>,
            default: () => ["name", "price", "change", "changePercent"]
        }
    },
    async fetch() {
        await this.$store.dispatch("globalMarket/fetchMarketData");
        this.tabsData = this.getGlobalMarketData();
    },
    data() {
        return {
            activeTab: "equities",
            isMobile: false,
            tabsData: [] as Config.ITabInfo[],
        };
    },
    computed: {
        globalMarketData(): GlobalMarketData {
            return this.$store.state.globalMarket.globalMarketData;
        },
        isLoading(): boolean {
            return this.globalMarketData ? false : true;
        },
        isMobileViewport(): boolean {
            return (
                this.$store.state?.layout?.windowSize?.width <=
          Constants.MOBILE_VIEWPORT_WIDTH || false
            );
        },
        tableHeight(): number | undefined {
            return this.isSmallComponent
                ? Constants.SMALL_COMPONENT_HEIGHT
                : undefined;
        },
        isSmallComponent(): boolean {
            return this.size?.toLowerCase() === "small";
        },
        showNetChangeColumn(): boolean {
            return !this.isSmallComponent;
        },
        componentStyle(): MarketTablesComponentStyle | undefined {
            return this.isSmallComponent ? "SMALL" : "DEFAULT";
        },
        tableRows(): ITableRow[] {
            if (this.activeTab === "commodities") {
                return this.transformData(this.globalMarketData.commodity);
            }
            else if (this.activeTab === "currencies") {
                return this.transformData(this.globalMarketData.currency);
            }
            else {
                return this.transformEquityData(this.globalMarketData.equity);
            }
        },
    },
    methods: {
        onTabChange(tabName: string): void {
            this.activeTab = tabName;
        },
        getGlobalMarketData(): Config.ITabInfo[] {
            return Config.GlobalMarketTabsData.map(data => {
                const marketFields = data.marketFields!.filter(fields => {
                    return this.headers.includes(fields.fieldName);
                });
                return {
                    id: data.id,
                    active: data.active,
                    text: data.text,
                    marketFields
                };
            });
        },
        transformData(data: Array<Commodity | ExchangeInfo>): ITableRow[] {
            return data.map((el: Commodity | ExchangeInfo) => {
                return {
                    name: el.name,
                    id: el.id,
                    price: el.close.value,
                    change: el.close.change,
                    changePercent: el.close.percentChange,
                } as ITableRow;
            });
        },
        transformEquityData(equityData: Array<MarketIndex>): ITableRow[] {
            return equityData.map((el: MarketIndex) => {
                return {
                    name: el.name,
                    price: el.last?.value,
                    change: el.last?.change,
                    ticker: el?.ticker,
                    changePercent: el.last?.percentChange,
                } as ITableRow;
            });
        },
        getIndexRouterLocation(security: ITableRow): Location {
            return {
                name: "mca-security",
                params: {
                    exchangeCode: "index",
                    symbol: security.ticker?.toLowerCase() ?? "",
                },
            };
        },
    },
    mounted() {
        this.$nextTick(() => {
            const mediaQuery = window.matchMedia("(max-width: 768px)");
            this.isMobile = mediaQuery.matches;
        });
    },
});
