
import Vue from 'vue';
import McaSpecialists from '@mca-components-ui/specialists';
import { PLACEHOLDERS } from "~/configs/images";
import { Contentstack } from '@mca-components-ui/models';

export default Vue.extend({
    components: {
        McaSpecialists
    },
    data() {
        return {
            PLACEHOLDERS
        };
    },
    computed: {
        specialists() {
            return  this.$store.state.lineup.specialists.map((story: { top_stories: any; }) => new Contentstack.TopStories(story.top_stories));
        }
    },
    async fetch () {
        await this.$store.dispatch('lineup/getSpecialistsFromCS');
    }
});
