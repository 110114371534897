
import Vue from "vue";
import { AdvertPlacement } from '@/configs/adverts';
import McaAdvertContainer from '@mca-components-ui/advert-container';

export default Vue.extend({
    components: {
        McaAdvertContainer
    },
    computed: {
        adverts(): Record<AdvertPlacement, string> {
            return this.$store.state.adverts.placements;
        },
        isDesktop(): boolean {
            return this.$store.getters['layout/windowWidth'] >= 1000;
        },
        userType(): string {
            return this.$store.getters['user/userType'];
        },
        isUserSegmented(): boolean {
            return this.$store.getters['user/isSegmented'];
        }
    }
});
