
import Vue from 'vue';
import MdsLoader from '@mds/loader';
import MarketIntradayMovement from '@mca-components-ui/market-intraday-movement';
import { getModule } from 'vuex-module-decorators';
import markets from '~/store/markets';
import type { IntradaySecurity } from '~/plugins/intraday-security';
import type { IMarketSecurity } from '@mca-components-ui/market-intraday-movement';


let marketsStore: markets;

export default Vue.extend({
    props: {
        frequency:{
            type: Number,
            default: 5
        },
        refreshTime:{
            type: Number,
            default: 300000
        },
        id: {
            type: String,
            required: true
        },
        name: {
            type: String
        }
    },
    components:{
        MdsLoader,
        MarketIntradayMovement
    },
    data() {
        return {
            refreshId: null as any,
            isDataLoading: false,
            security: {
                id: this.id,
                name: this.name,
            } as IMarketSecurity
        };
    },
    computed: {
        isMobileViewport (): boolean {
            const currentWidth = this.$store.state?.layout?.windowSize?.width;
            return (process.client && currentWidth && currentWidth <= 600) ? true : false;
        },
        includeTimeseries (): boolean {
            const currentWidth = this.$store.state?.layout?.windowSize?.width;
            return (process.client && currentWidth && currentWidth <= 600) ? false : true;
        }
    },
    beforeDestroy () {
        clearInterval(this.refreshId);
    },
    async fetch() {
        if(this.refreshId != null) {
            clearInterval(this.refreshId);
            this.refreshId = null;
        }
        this.isDataLoading = true;
        await this.updateChartData();
        this.isDataLoading = false;
    },
    mounted() {
        marketsStore = getModule(markets, this.$store);
    },
    methods:{      
        subscribeToRefresh () {
            if(this.refreshId==null) {
                this.refreshId = setInterval(() => {
                    this.updateChartData();
                }, this.refreshTime);
            }
        },
        async updateChartData() {
            try {
                const secInput = { id: this.id, name: this.name, frequency: this.frequency, includeTimeseries: this.includeTimeseries};
                this.setSecurity(await this.$nuxt.context.$getIntradaySecurity(secInput));
                marketsStore?.setMarketsMovementUpdateTime(new Date());
                this.subscribeToRefresh();
            }
            catch (error) {
                this.isDataLoading = false;
            }
        },
        setSecurity(sec:IntradaySecurity) {
            this.security = {
                name: this.name || sec.name || 'NA',
                id: this.id || sec.id || 'NA',
                timeseries: sec.timeseries,
                price: sec.lastPrice,
                percentageChange: sec.netChangePercent || 0,
                previousClose: sec.previousClose,
                marketOpenTime: sec.openTime,
                marketCloseTime: sec.closeTime
            };
        }
    }
});
