
import Vue from 'vue';
import McaExplore, { ILink } from '@mca-components-ui/explore';
import { State as lineupModule } from '@/store/lineup';

export default Vue.extend({
    components: {
        McaExplore
    },
    computed: {
        links(): ILink[] {
            return (this.$store.state.lineup as lineupModule).explore ?? [];
        }
    },
    async fetch() {
        await this.$store.dispatch('lineup/getExploreFromCS');
    }
});
