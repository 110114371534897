var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"market-movements"},[_c('div',{staticClass:"market-movements__asof-time"},[_c('h1',[_vm._v("Morningstar Australia - Empowering Investor Success")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.asOfTime))])]),_vm._v(" "),_c('client-only',[_c('VueSlickCarousel',_vm._b({scopedSlots:_vm._u([{key:"prevArrow",fn:function(){return [_c('div',{staticClass:"slick-arrow-custom"},[_c('mds-icon',{staticClass:"slick-arrow-custom__icon",attrs:{"name":"caret-left","size":"medium"}})],1)]},proxy:true},{key:"nextArrow",fn:function(){return [_c('div',{staticClass:"slick-arrow-custom"},[_c('mds-icon',{staticClass:"slick-arrow-custom__icon",attrs:{"name":"caret-right","size":"medium"}})],1)]},proxy:true}])},'VueSlickCarousel',_vm.slickSettings,false),_vm._l((_vm.indicesOptions),function(sec){return _c('div',{key:sec.id},[_c('router-link',{staticClass:"link-item",attrs:{"itemprop":"url","to":{
                  name: 'investments-security-exchangeCode-symbol-view',
                  params: {
                    exchangeCode:'index',
                    symbol: sec.ticker
                  },
                }}},[_c('chart-intraday-movement',{staticClass:"market-movements__item",attrs:{"id":sec.id,"name":sec.label,"frequency":_vm.frequency,"refresh-time":_vm.refreshTime}})],1)],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }