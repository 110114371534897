
import Vue from "vue";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { MetaInfo } from "vue-meta";
import { GlobalMarkets } from "~/configs/markets";
import { MdsButton } from '@mds/button';

export default Vue.extend({
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsButton
    },
    head(): MetaInfo {
        return this.$store.state.headElement?.meta;
    },
    computed:{
        isVisibleAR() {
            return this.$store.state.contentstack.analystReports.isVisible;
        }
    },
    async fetch() {
        await this.$store.dispatch("headElement/fetchContentHeadElements", {
            canonical: `https://${this.$store.state.host}${this.$route.path}`,
            slug_id: "/home",
        });
    },
    data() {
        return {
            GlobalMarkets,
            headers: ["name", "price", "changePercent"]
        };
    },
});
