
import Vue from 'vue';
import LearnToInvestCard from '@mca-components-ui/learn-to-invest-card';
import VueSlickCarousel from 'vue-slick-carousel';
import type {MCA} from '@mca-components-ui/types';
import { Contentstack } from '@mca-components-ui/models';
import MdsIcon from '@mds/icon';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import { PLACEHOLDERS } from '~/configs/images';
import { MdsButton } from '@mds/button';

export default Vue.extend({
    props: {
        titleIcon: {
            type: String,
            default: 'caret-right',
            required: false
        }
    },
    components:{
        LearnToInvestCard,
        VueSlickCarousel,
        MdsIcon,
        MdsButton
    },
    data() {
        return {
            slickSettings: {
                arrows: true,
                draggable: true,
                infinite: false,
                slidesToShow: 2.55,
                slidesToScroll: 2,
                variableWidth: false,
                swipe: true,
                rows: 1,
                responsive: [{}]
            }
        };
    },
    computed: {
        docs(): MCA.Models.Contentstack.ITopStories[] {
            return this.$store.state.lineup.learnToInvest.learn_to_invest_lineup.map((doc: { cmsgo_docid_lookup: any; }) => {
                let item = new Contentstack.CmsgoDocidLookup(doc.cmsgo_docid_lookup);
                item.imageUrl = item.imageUrl ? '/_ipx/w_240,q_100,f_webp/'+item.imageUrl : PLACEHOLDERS.avatar;
                return item;
            });
        },
        title(): string {
            return this.$store.state.lineup.learnToInvest.title;
        }
    },
    async fetch () {
        await this.$store.dispatch('lineup/getLearnToInvestFromCS');
    },
    mounted () {
        this.$nextTick(() => {
            this.slickSettings.responsive = [{
                breakpoint: 300,
                settings: {
                    slidesToShow: 0.9,
                    slidesToScroll: 0.9,
                    arrows: false
                }
            },{
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.15,
                    slidesToScroll: 1,
                    arrows: false
                }
            },{
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.25,
                    slidesToScroll: 1,
                    arrows: false
                }
            },{
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.75,
                    slidesToScroll: 1,
                    arrows: false
                }
            },{
                breakpoint: 678,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1.6,
                    arrows: false
                }
            },{
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1.5,
                    arrows: false
                }
            },{
                breakpoint: 791,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1.5,
                    arrows: false
                }
            }, {
                breakpoint: 850,
                settings: {
                    slidesToShow: 1.9,
                    slidesToScroll: 1.7
                }
            },{
                breakpoint: 999,
                settings: {
                    slidesToShow: 2.0,
                    slidesToScroll: 1.8
                }
            },{
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1.5
                }
            },{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1.6
                }
            },{
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2.0,
                    slidesToScroll: 1.8
                }
            },{
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1.6
                }
            }];
        });

    },
});
