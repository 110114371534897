
import Vue from 'vue';
import Query from "~/services/queries/getDocumentsInCollection.gql";
import { CacheService } from '~/services/cache';
import type { MCA }  from '@mca-components-ui/types';
import { Content } from "@mca-components-ui/models";
import McaCollections from '@mca-components-ui/collections';

const keywordMap =  new Map([
    ['Stocks', 'STOCKS'],
    ['ETFs', 'ETFS'],
    ['Funds', 'FUNDS'],
    ['Markets', 'MARKETS'],
    ['Personal Finance', 'PERSONAL_FINANCE'],
    ['Retirement', 'RETIREMENT']
]);

type getDocumentsCollectionResponse = {
    getDocumentsCollection: {
        documents: MCA.Api.IDocument[];
    };
};

export default Vue.extend({
    props:{
        collectionName: {
            type: String,
            required: true
        }
    },
    components: {
        McaCollections
    },
    data() {
        return {
            isDataLoading: false,
            documentsRes: [] as MCA.Api.IDocument[],
            globalMarketInstrumentsCollection: {} as any
        };
    },
    computed: {
        documents(): MCA.Models.IDocument[] {
            return this.documentsRes.map((document) => new Content.Document(document));
        },
        keyword(): string | undefined {
            return keywordMap.get(this.collectionName);
        },
        to(): string {
            return `/insights/${this.keyword?.replace(/_/g,"-").toLowerCase()}`;
        }
    },
    async fetch() {
        await this.getCollections();
    },
    methods: {
        async getCollections() {
            this.isDataLoading = true;
            const queryParams = {
                keyword: this.keyword,
                page: 1,
                pageSize: 3
            };
            const cacheId = Object.values(queryParams).join('-');
            const res = await CacheService.computeIfAbsent<Promise<MCA.Api.IDocument[]>>('content', cacheId, () => {
                return this.$apollo.query<getDocumentsCollectionResponse>({query: Query, variables: queryParams}).then(response =>
                    response.data.getDocumentsCollection.documents);
            });
            this.isDataLoading = false;
            this.documentsRes = res;
        }
    }

});
