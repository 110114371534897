
import Vue from 'vue';
import * as Config from '~/configs/markets';
import { MdsButton } from '@mds/button';
import MdsPopover from '@mds/popover';
import { nanoid }  from 'nanoid';

export default Vue.extend({
    props:{
        componentSize: {
            type: String
        },
        tooltipText: {
            type: String
        }
    },
    components: {
        MdsButton,
        MdsPopover
    },
    data() {
        return {
            uId: nanoid(6),
            headerTooltipToggle: false
        };
    },
    computed: {        
        indicesOptions(): Array<Config.SelectOption> {
            return Config.GetSelectOptionsByIds(Config.MarketsIntradayCompareIds);
        },
        indicesToCompare(): Array<string> {            
            return Config.MarketsIntradayCompareIds.slice(0,2) || [];
        }
    }
});
