import { render, staticRenderFns } from "./MarketReports.vue?vue&type=template&id=225fa728&scoped=true"
import script from "./MarketReports.vue?vue&type=script&lang=ts"
export * from "./MarketReports.vue?vue&type=script&lang=ts"
import style0 from "./MarketReports.vue?vue&type=style&index=0&id=225fa728&prod&lang=scss&scoped=true"
import style1 from "./MarketReports.vue?vue&type=style&index=1&id=225fa728&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "225fa728",
  null
  
)

export default component.exports