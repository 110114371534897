
import Vue from 'vue';
import McaMarketingCta from '@mca-components-ui/marketing-cta';
import ContentStackService from '~/services/content-stack';
import type { UserVariation } from '@/configs/user';
import type { State as UserStoreState } from '@/store/user';
import { Gtm } from '~/configs/common';
import type { NewsletterCtaPlacement, NewsletterCtaPlacementVariationBlock, UserType } from '@/services/content-stack/types';

export default Vue.extend({
    props: {
        /**
         * Unique Placement ID
         */
        placement: {
            type: String,
            required: true
        },
        userType: {
            type: String as () => UserType,
            required: true
        }
    },
    data() {
        return {
            isViewed: false,
            isLoading: true,
            cta: {} as NewsletterCtaPlacement
        };
    },
    async mounted() {

        const contentStack = new ContentStackService();

        try {
            this.cta        = await contentStack.getNewsletterCta(this.placement, this.userType);
            this.isLoading  = false;
        }
        catch(e) {
            console.error('Error loading CTA', this.placement, this.userType);
        }

        const observer = new IntersectionObserver((entries) => {
            if(entries[0].isIntersecting && !this.isViewed) {

                this.isViewed = true;

                this.$gtm.push({
                    event: Gtm.eventName,
                    category: 'cta_impression',
                    action: 'newsletter_cta_impression',
                    label: `${this.placement}-${this.userVariation}`
                });
            }
        });

        this.$nextTick(() => {
            observer.observe(this.$el);
        });
    },
    components: {
        McaMarketingCta
    },
    methods: {
        onCtaClick(): void {
            window.open(
                this.$router.resolve({
                    name: 'newsletters-subscribe',
                    query: {
                        referid: this.referid,
                        placement: this.placement
                    }
                }).href,
                '_blank'
            );
        }
    },
    computed: {
        userVariation(): UserVariation {
            return (this.$store.state.user as UserStoreState).abTesting.variation;
        },
        referid(): string {
            return `${this.userType}-${this.userVariation}`;
        },
        /**
         * Gets the correct A/B test variation result, or if it does not exist just return the first version
         */
        variation(): NewsletterCtaPlacementVariationBlock | undefined {

            if(this.isLoading) {
                return;
            }

            const variation = this.cta.variations.find(v => v.cta.variation === this.userVariation) || this.cta.variations[0];

            if(variation) {
                return variation.cta;
            }

            return undefined;
        }
    }
});
