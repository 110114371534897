export interface SelectOption {
    id: string;
    label: string;
    selected?: boolean;
    name?: string;
    title?: string;
    ticker?: string;
}

export interface MarketTabs {
    fieldName: string;
    text: string;
    align?: string;
}
export interface ITabInfo {
    id: string;
    active: boolean;
    text: string;
    fields?: string[];
    marketFields?: Array<MarketTabs>;
}

export interface ITableRow {
    name: string;
    id?: string;
    ticker: string;
    price?: number | null;
    change?: number | null;
    changePercent?: number | null;
    exchangeCode: string | null;
    apir: number | null;
}

export const GetSelectOptionsByIds = (
    ids: string[],
    selectedId?: string
): SelectOption[] => {
    const toRet: SelectOption[] = [];
    const idList: { [key: string]: SelectOption } = {
        XIUSA04GJD: { id: "XIUSA04GJD", label: "ASX All Ordinaries", ticker:"xao"},
        XIUSA04GJG: { id: "XIUSA04GJG", label: "S&P/ASX 200", ticker:"xjo" },
        FOUSA05SH9: { id: "FOUSA05SH9", label: "NZX50 Index", ticker:"nz50" },
        F00000T5UB: { id: "F00000T5UB", label: "Morningstar AU GR AUD", ticker:"msegauta" },
        F00000T60W: { id: "F00000T60W", label: "Morningstar NZ GR NZD", ticker:"msegnztn"  },
        XIUSA0001Z: { id: "XIUSA0001Z", label: "HKSE", ticker:"hsi" },
        FOUSA05SFQ: { id: "FOUSA05SFQ", label: "Nikkei 225", ticker:"100000018" },
        FOUSA05TC8: { id: "FOUSA05TC8", label: "SSE Composite Index", ticker:"000001" },
        FOUSA06CGR: { id: "FOUSA06CGR", label: "CAC 40", ticker:"px1" },
        XIUSA0001M: { id: "XIUSA0001M", label: "DAX 40", ticker:"dax"},
        XIUSA04CG4: { id: "XIUSA04CG4", label: "FTSE 100", ticker:"ukx" },
        XIUSA00007: { id: "XIUSA00007", label: "Dow Jones", ticker:"!dji" },
        XIUSA000O2: { id: "XIUSA000O2", label: "NASDAQ", ticker:"@cco" },
        XIUSA000OA: { id: "XIUSA000OA", label: "S&P 500", ticker:"spx" },
        F00000Y5CZ: { id: "F00000Y5CZ", label: "Morningstar US Lge-Mid TR USD", ticker:"msegusat" },
    };

    for (const id of ids) {
        if (idList[id]) {
            const toPush = { ...idList[id] };
            if (selectedId && id == selectedId) {
                toPush.selected = true;
            }
            else {
                toPush.selected = false;
            }
            toRet.push(toPush);
        }
    }
    return toRet;
};

export const MarketsCentreRegions = new Map<string, SelectOption[]>([
    [
        "australia",
        [
            { id: "XIUSA04GJD", label: "ASX All Ordinaries", ticker:"xao" },
            { id: "XIUSA04GJG", label: "S&P/ASX 200", ticker:"xjo" },
            { id: "FOUSA05SH9", label: "NZX50 Index", ticker:"nz50" },
            { id: "F00000T5UB", label: "Morningstar AU GR AUD", ticker:"msegauta" },
            { id: "F00000T60W", label: "Morningstar NZ GR NZD", ticker:"msegnztn" },
        ],
    ],
    [
        "asia",
        [
            { id: "XIUSA0001Z", label: "HKSE", ticker:"hsi" },
            { id: "FOUSA05SFQ", label: "Nikkei 225", ticker:"100000018" },
            { id: "FOUSA05TC8", label: "SSE Composite Index", ticker:"000001" },
        ],
    ],
    [
        "europe",
        [
            { id: "FOUSA06CGR", label: "CAC 40", ticker:"px1" },
            { id: "XIUSA0001M", label: "DAX 40", ticker:"dax" },
            { id: "XIUSA04CG4", label: "FTSE 100", ticker:"ukx" },
        ],
    ],
    [
        "united-states",
        [
            { id: "XIUSA00007", label: "Dow Jones", ticker:"!dji" },
            { id: "XIUSA000O2", label: "NASDAQ", ticker:"@cco" },
            { id: "XIUSA000OA", label: "S&P 500", ticker:"spx" },
            { id: "F00000Y5CZ", label: "Morningstar US Lge-Mid TR USD", ticker:"msegusat" },
        ],
    ],
]);

export const MarketMoversRegions = new Map<string, SelectOption[]>([
    [
        "australia",
        [
            { id: "XIUSA04GJD", label: "ASX All Ordinaries", selected: true },
            { id: "XIUSA04GJB", label: "S&P/ASX 100", selected: false },
            { id: "XIUSA04GJG", label: "S&P/ASX 200", selected: false },
            { id: "XIUSA04GJH", label: "S&P/ASX 300", selected: false },
            { id: "FOUSA05SH9", label: "NZX50 Index", selected: false },
        ],
    ],
    [
        "asia",
        [
            { id: "XIUSA0001Z", label: "HKSE", selected: true },
            { id: "FOUSA05SFQ", label: "Nikkei 225", selected: false },
        ],
    ],
    [
        "europe",
        [
            { id: "FOUSA06CGR", label: "CAC 40", selected: true },
            { id: "XIUSA0001M", label: "DAX 40", selected: false },
            { id: "XIUSA04CG4", label: "FTSE 100", selected: false },
        ],
    ],
    [
        "united-states",
        [
            { id: "XIUSA00007", label: "Dow Jones", selected: true },
            { id: "XIUSA000O2", label: "NASDAQ", selected: false },
            { id: "XIUSA000OA", label: "S&P 500", selected: false },
        ],
    ],
]);

export const MarketsIntradayCompareIds = [
    "XIUSA04GJD",
    "XIUSA04GJG",
    "FOUSA05SH9",
    "XIUSA0001Z",
    "FOUSA05SFQ",
    "FOUSA05TC8",
    "FOUSA06CGR",
    "XIUSA0001M",
    "XIUSA04CG4",
    "XIUSA00007",
    "XIUSA000O2",
    "XIUSA000OA",
];

export const MarketsTimePeriod = [
    { id: "M1", label: "1 Month", selected: false },
    { id: "M3", label: "3 Months", selected: false },
    { id: "Y1", label: "1 Year", selected: true },
    { id: "Y5", label: "5 Years", selected: false },
    { id: "Y10", label: "10 Years", selected: false },
] as SelectOption[];

export const AsxMarketOptions = [
    { id: "XIUSA04GJD", label: "ASX All Ordinaries", ticker:"xao" },
    { id: "XIUSA04GJG", label: "S&P/ASX 200", ticker:"xjo" },
    { id: "FOUSA05SH9", label: "NZX50 Index", ticker:"nz50" },
] as SelectOption[];

export const AsxMarketOptionsForSALPage = [
    { id: "XIUSA04GJD", label: "All Ordinaries" },
    { id: "FOUSA06CGR", label: "CAC 40" },
    { id: "XIUSA0001M", label: "DAX 40" },
    { id: "XIUSA00007", label: "Dow JONES (US)" },
    { id: "XIUSA04CG4", label: "FTSE 100" },
    { id: "XIUSA0001Z", label: "HKSE" },
    { id: "XIUSA000O2", label: "NASDAQ" },
    { id: "FOUSA05SFQ", label: "Nikkei 225" },
    { id: "FOUSA05SH9", label: "NZX 50 Index" },
    { id: "XIUSA000OA", label: "S&P 500" },
    { id: "XIUSA04GJG", label: "S&P/ASX 200" },
    { id: "FOUSA05TC8", label: "SSE Composite Index" },
] as SelectOption[];

export const GetNumberOfMonths = (id: string): number => {
    return (
        {
            M1: 1,
            M3: 3,
            M6: 6,
            Y1: 12,
            Y3: 36,
            Y5: 60,
            Y10: 120,
        }[id] || 0
    );
};

export const CountryByRegion = new Map<string, SelectOption[]>([
    [
        "australia",
        [
            { id: "0P00012P92", label: "Australia", selected: true },
            { id: "0P00012PFN", label: "New Zealand" },
        ],
    ],
    [
        "asia",
        [
            { id: "0P00012PA3", label: "China", selected: true },
            { id: "0P00012PDI", label: "Hong Kong" },
            { id: "0P00012PEO", label: "Japan" },
            { id: "0P00012PEU", label: "South Korea" },
            { id: "0P00012PGZ", label: "Singapore" },
            { id: "0P00012PDT", label: "India" },
        ],
    ],
    [
        "europe",
        [
            { id: "0P00012P9F", label: "Belgium", selected: true },
            { id: "0P00012PCU", label: "Finland" },
            { id: "0P00012PD0", label: "France" },
            { id: "0P00012PD5", label: "Germany" },
            { id: "0P00012PEI", label: "Italy" },
            { id: "0P00012PFH", label: "Netherlands" },
            { id: "0P00013F5A", label: "Norway" },
            { id: "0P00012PHH", label: "Sweden" },
            { id: "0P00012PHB", label: "Spain" },
            { id: "0P00012PHN", label: "Switzerland" },
            { id: "0P00012PIC", label: "UK" },
        ],
    ],
    [
        "united-states",
        [
            { id: "0P00001GJH", label: "United States", selected: true },
            { id: "0P00012P9R", label: "Canada" },
        ],
    ],
]);

export const CountryCodeById = new Map([
    ["0P00012P92", "au"],
    ["0P00012PFN", "nz"],
    ["0P00012PA3", "cn"],
    ["0P00012PDI", "hk"],
    ["0P00012PEO", "jp"],
    ["0P00012PEU", "kr"],
    ["0P00012PGZ", "sg"],
    ["0P00012PDT", "in"],
    ["0P00012P9F", "be"],
    ["0P00012PCU", "fi"],
    ["0P00012PD0", "fr"],
    ["0P00012PD5", "de"],
    ["0P00012PEI", "it"],
    ["0P00012PFH", "nl"],
    ["0P00013F5A", "no"],
    ["0P00012PHH", "se"],
    ["0P00012PHB", "es"],
    ["0P00012PHN", "ch"],
    ["0P00012PIC", "gb"],
    ["0P00001GJH", "us"],
    ["0P00012P9R", "ca"],
]);

export const SectorChartColors = [
    "#ef7622",
    "#ef7622cc",
    "#ef762299",
    "#ef762266",
    "#1f55a5",
    "#1f55a5cc",
    "#1f55a599",
    "#1f55a566",
    "#518428",
    "#518428cc",
    "#51842899",
] as Array<string>;

export const ASXMarket = {
    reportType: "morningnote",
    title: "Seven-day streak puts ASX close to record",
};

export const GlobalMarkets = {
    caption: "Markets",
    reportType: "morningnote",
};

export const GlobalMarketsButtonGroup = [
    {
        id: "australia",
        text: "Australia",
        active: true,
    },
    {
        id: "asia",
        text: "Asia",
        active: false,
    },
    {
        id: "europe",
        text: "Europe",
        active: false,
    },
    {
        id: "north-america",
        text: "North America",
        active: false,
    },
];

export const GlobalMarketTabsData = [
    {
        id: "equities",
        active: true,
        text: "Equity",
        tableHeight: 200,
        marketFields: [
            {
                fieldName: "name",
                text: "Index",
            },
            {
                text: "Last price",
                fieldName: "price",
                align: "right",
            },
            {
                text: "Change",
                fieldName: "change",
                align: "right",
            },
            {
                text: "% Change",
                fieldName: "changePercent",
                align: "right",
            },
        ],
    },
    {
        id: "commodities",
        active: false,
        text: "Commodity",
        marketFields: [
            {
                fieldName: "name",
                text: "Commodity (USD)",
            },
            {
                text: "Last price",
                fieldName: "price",
                align: "right",
            },
            {
                text: "Change",
                fieldName: "change",
                align: "right",
            },
            {
                text: "% Change",
                fieldName: "changePercent",
                align: "right",
            },
        ],
    },
    {
        id: "currencies",
        active: false,
        text: "Currency",
        marketFields: [
            {
                fieldName: "name",
                text: "Currency Pair",
            },
            {
                text: "Last price",
                fieldName: "price",
                align: "right",
            },
            {
                text: "Change",
                fieldName: "change",
                align: "right",
            },
            {
                text: "% Change",
                fieldName: "changePercent",
                align: "right",
            },
        ],
    },
] as ITabInfo[];

export const GlobalMarketTableData = {
    equities: [
        {
            id: "0P0000TE32",
            name: "The Star Entertainment Group Ltd",
            ticker: "SGR",
            price: 3.205,
            change: -0.095,
            changePercent: -2.8788,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P00006W6Q",
            name: "AMP Ltd",
            ticker: "AMP",
            price: 1.1125,
            change: 0.0075,
            changePercent: 0.6787,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P00006WI4",
            name: "Fortescue Metals Group Ltd",
            ticker: "FMG",
            price: 14.88,
            change: -0.12,
            changePercent: -0.8,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P00006WZD",
            name: "Telstra Corp Ltd",
            ticker: "TLS",
            price: 3.84,
            change: -0.01,
            changePercent: -0.2597,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P00006W87",
            name: "Alumina Ltd",
            ticker: "AWC",
            price: 2.24,
            change: 0.09,
            changePercent: 4.186,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P0001608R",
            name: "South32 Ltd",
            ticker: "S32",
            price: 3.65,
            apir: "",
            exchangeCode: "ASX",
        },
    ],
    commodities: [
        {
            id: "0P00006W87",
            name: "Alumina Ltd",
            ticker: "AWC",
            price: 2.24,
            change: 0.09,
            changePercent: 4.186,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P000170FJ",
            name: "IDP Education Ltd",
            ticker: "IEL",
            price: 34.63,
            change: -0.97,
            changePercent: 2.8818,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P00006WBG",
            name: "BlueScope Steel Ltd",
            ticker: "BSL",
            price: 21.265,
            change: 0.445,
            changePercent: 2.1374,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P00006WCJ",
            name: "CSL Ltd",
            ticker: "CSL",
            price: 292.98,
            change: 5.48,
            changePercent: 1.9061,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P00006WHU",
            name: "Fisher & Paykel Healthcare Corp Ltd",
            ticker: "FPH",
            price: 28.4,
            change: 0.25,
            changePercent: 0.8881,
            apir: "",
            exchangeCode: "ASX",
        },
    ],
    currencies: [
        {
            id: "0P000080EL",
            name: "Ansell Ltd",
            ticker: "ANN",
            price: 32.32,
            change: -1.52,
            changePercent: -4.4917,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P00006X2Q",
            name: "REA Group Ltd",
            ticker: "REA",
            price: 151.7,
            change: 6.35,
            changePercent: -4.0177,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P00006X2A",
            name: "Worley Ltd",
            ticker: "WOR",
            price: 10.43,
            change: -0.36,
            changePercent: -3.3364,
            apir: "",
            exchangeCode: "ASX",
        },
        {
            id: "0P0000TE32",
            name: "The Star Entertainment Group Ltd",
            ticker: "SGR",
            price: 3.205,
            change: -0.095,
            changePercent: -2.8788,
            apir: "",
            exchangeCode: "ASX",
        },
    ],
};
export const priceFairValueTabs = [
    {
        id: "australia",
        active: true,
        text: "AU/NZ",
        tableHeight: 200,
        fields: [],
        fairValueFields: [
            {
                fieldName: "countryName",
                text: "Country",
            },
            {
                text: "Current",
                fieldName: "currentPrice",
                align: "right",
            },
            {
                text: "1 year ago",
                fieldName: "yearAgo",
                align: "right",
            },
        ],
    },
    {
        id: "asia",
        active: false,
        text: "Asia",
        fields: [],
        fairValueFields: [
            {
                fieldName: "countryName",
                text: "Country",
            },
            {
                text: "Current",
                fieldName: "currentPrice",
                align: "right",
            },
            {
                text: "1 year ago",
                fieldName: "yearAgo",
                align: "right",
            },
        ],
    },
    {
        id: "europe",
        active: false,
        text: "Europe",
        fields: [],
        fairValueFields: [
            {
                fieldName: "countryName",
                text: "Country",
            },
            {
                text: "Current",
                fieldName: "currentPrice",
                align: "right",
            },
            {
                text: "1 year ago",
                fieldName: "yearAgo",
                align: "right",
            },
        ],
    },
    {
        id: "northAmerica",
        active: false,
        text: "North America",
        fields: [],
        fairValueFields: [
            {
                fieldName: "countryName",
                text: "Country",
            },
            {
                text: "Current",
                fieldName: "currentPrice",
                align: "right",
            },
            {
                text: "1 year ago",
                fieldName: "yearAgo",
                align: "right",
            },
        ],
    },
];

export const priceFairValueContent = {
    caption: "Price/fair value",
    size: "small",
};

export const DividendMarketsButtonGroup = [
    {
        id: "E0EXG$XASX",
        text: "Australia",
        active: true,
    },
    {
        id: "E0EXG$XNZE",
        text: "New Zealand",
        active: false,
    }
];

export const DividendTableHeaders = [
    {
        fieldName: 'Name',
        text: 'Name',
        sortable: true,
        style: {
            width: '40%'
        },
        mobileStyle: {
            width: '90px'
        },
        align: 'left'
    },
    {
        fieldName: 'ExDate',
        text: 'Ex-dividend',
        sortable: true,
        style: {
            width: '20%'
        },
        mobileStyle: {
            width: '90px'
        },
        align: 'right'
    },
    {
        fieldName: 'PayDate',
        text: 'Pay date',
        sortable: true,
        style: {
            width: '20%'
        },
        mobileStyle: {
            width: '90px'
        },
        align: 'right'
    },
    {
        fieldName: 'DivCashAmount',
        text: 'Amount ($)',
        sortable: true,
        style: {
            width: '20%'
        },
        mobileStyle: {
            width: '90px'
        },
        align: 'right'
    },
    {
        fieldName: 'FrankedRate',
        text: 'Franking (%)',
        sortable: true,
        style: {
            width: '20%'
        },
        mobileStyle: {
            width: '90px'
        },
        align: 'right'
    }, 
];