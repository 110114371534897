
import Vue from 'vue';
import MdsModal from '@mds/modal';
import { nanoid }  from 'nanoid';


export default Vue.extend({
    components: {
        MdsModal
    },
    props:{
        isActive: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Our equity ratings'
        },
        width: {
            type: String,
            default: '900px'
        }
    },
    data() {
        return {
            uId: nanoid(6),
            visible: false
        };
    },
    model: {
        prop: 'isActive',
        event: 'modal-dismissed',
    },
});
