
import Vue from 'vue';
import { MdsButton } from '@mds/button';
import MdsPopover from '@mds/popover';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MiniChartCard  from '@mca-components-ui/mini-chart-card';
import type { SelectOption } from '~/configs/markets';
import type {IntradaySecurityInput as ChartInput, IntradaySecurity as OverviewChart} from '~/plugins/intraday-security';
import { nanoid }  from 'nanoid';


const InitialChartData: OverviewChart = {
    id: '',
    timeseries : [],
    previousClose : 0,
    netChange: 0,
    netChangePercent: 0,
    lastPrice : 0
};

export default Vue.extend ({
    components: {
        MdsButton,
        MdsListGroup,
        MdsListGroupItem,
        MdsPopover,
        MiniChartCard
    },
    props: {
        frequency:{
            type: Number,
            default: 5
        },
        securityOptions: {
            type: Array as () => Array<SelectOption>,
            default: () => []
        },
        initialId: {
            type: String
        },
        caption:{
            type: String
        },
        componentSize:{
            type: String
        },
        refreshTime:{
            type: Number,
            default: 300000
        }
    },
    data() {
        return {
            uId: nanoid(6),
            isDataLoading: true,
            securityOptionsToggle: false,
            selectedIndexId: '',
            chart: Object.assign(InitialChartData) as OverviewChart,
    		refreshId: null as any
        };
    },
    watch: {
        selectedIndexId: function() {
            this.getIndexChartData();
        }
    },
    computed:{
        selectedIndex(): SelectOption {
            return this.securityOptions.find(m => m.id.toString() === this.selectedIndexId)
                || this.securityOptions.find(m => m.id.toString() === this.initialId) 
                || this.securityOptions[0];
        },
        isSmallComponent(): boolean {
            return this.componentSize?.toLowerCase() == 'small';
        },
        mdsComponentSize(): string {
            return  this.isSmallComponent ? 'medium': 'large';
        },
        chartInput():ChartInput {
            let toReturn: ChartInput ={
                id : this.selectedIndex.id,
                frequency : this.frequency
            };
            if(this.selectedIndex.name) {
                toReturn.name =this.selectedIndex.name;
            }
            return toReturn;
        },
        indexRoute(): {name: string;params: {exchangeCode: string; symbol: string}} {
            return {
                name: 'investments-security-exchangeCode-symbol-view',
                params: {
                    exchangeCode:'index',
                    symbol: this.selectedIndex.ticker!
                },
            };
        }
    },
    created() {
        if(!this.selectedIndexId || this.selectedIndexId.length == 0) {
            this.selectedIndexId = this.initialId;
        }
    },
    beforeDestroy () {
        clearInterval(this.refreshId);
    },
    methods: {
        subscribeToRefresh () {
            if(this.chart.isMarketOpen && this.refreshId==null) {
                this.refreshId = setInterval(() => {
                    this.updateChartData();
                }, this.refreshTime);
            }
        },
        async getIndexChartData() {
            clearInterval(this.refreshId);
            this.refreshId = null;
            this.isDataLoading = true;
            await this.updateChartData();
            this.isDataLoading = false;
        },
        async updateChartData() {
            try {
                this.chart = await this.$nuxt.context.$getIntradaySecurity(this.chartInput);
                this.subscribeToRefresh();
            }
            catch (error) {
                this.resetChartData();
                this.isDataLoading = false;
            }
        },
        resetChartData() {
            this.chart = Object.assign(InitialChartData);
        },
        onOverviewsecurityOptionsItemClick(menu: SelectOption) {
            this.securityOptionsToggle = false;
            this.selectedIndexId = menu.id.toString();
        }
    }
});
