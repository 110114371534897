
import Vue from 'vue';
import McaLineupSecondaryVideo from '@mca-components-ui/lineup-secondary-video';
import {PLACEHOLDERS} from '~/configs/images';
import { MdsButton } from '@mds/button';

export default Vue.extend({
    components: {
        McaLineupSecondaryVideo,
        MdsButton
    },
    data() {
        return {
            PLACEHOLDERS
        };
    },
    computed: {
        podcasts(): any {
            return this.$store.state.lineup.podcasts;
        },
        podcastImage(): string {
            return this.podcasts?.podcast_image?.url;
        },
        buttonText(): string {
            return this.podcasts.button?.button_properties?.label;
        }
    },
    async fetch () {
        await this.$store.dispatch('lineup/getPodcastsFromCS');
    }
});
