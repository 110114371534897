
import Vue from 'vue';
import MdsLoader from '@mds/loader';
import ChartTimeseries from '@mca-components-ui/chart-timeseries';
import Query from '~/services/queries/getMarketValuation.gql';
import { Types as ApiTypes } from '@mca-dfd-api/graphql-schema';
import { nanoid }  from 'nanoid';
import type {ITimeseriesItem} from '@mca-components-ui/chart-timeseries';

type valuationResponse = {
    getMarketValuation: ApiTypes.MarketValuationPayload
}

export default Vue.extend ({
    components: {
        ChartTimeseries,
        MdsLoader
    },
    props: {
        securityId: {
            type: String
        },
        caption:{
            type: String
        },
        timePeriod:{
            type: String,
            default: 'Y1'
        },
        frequency:{
            type: String,
            default: "MONTHLY"
        },
        chartHeight: {
            type: Number,
            default: 420
        }
    },
    data() {
        return {
            uId: nanoid(6),
            isDataLoading: false,
            timeseries: [] as Array<ITimeseriesItem>
        };
    },
    watch:{
        securityId() {
            this.getChartData();
        },
        timePeriod() {
            this.getChartData();
        }
    },
    async fetch() {
        await this.getChartData();
    },
    methods: {
        async getChartData() {
           
            try {
                this.isDataLoading = true;                
                const response = await this.$apollo.query<valuationResponse>({
                    query: Query,
                    variables: {
                        ids: [this.securityId],
                        frequency: this.frequency,
                        period: this.timePeriod
                    },
                    fetchPolicy: 'network-only'
                });
                this.isDataLoading = false;
                this.updateChartData(response.data.getMarketValuation);
            }
            catch (ex) {
                this.timeseries = [];
                this.isDataLoading = false;
            }
        },
        updateChartData(rawData: ApiTypes.MarketValuationPayload) {
            if(rawData && rawData.valuations && rawData.valuations.length >0 && rawData.valuations[0]?.values) {
                const valuation = Object.assign(rawData.valuations[0]);
                this.timeseries = valuation["values"] as Array<ITimeseriesItem>;
            }
            else{
                this.timeseries = [];
            }
        }
    }
});
