
import Vue from 'vue';
import * as Config from '~/configs/markets';
import { MdsButton } from '@mds/button';
import MdsPopover from '@mds/popover';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsIcon from '@mds/icon';
import { nanoid }  from 'nanoid';
import type { Location } from 'vue-router';

export default Vue.extend({
    components: {
        MdsButton,
        MdsListGroup,
        MdsListGroupItem,
        MdsPopover,
        MdsIcon,
    },
    props:{
        region: {
            type: String
        },
        componentSize:{
            type: String
        },
        tooltipText: {
            type: String
        }
    },
    computed: {
        selectedMarket(): string {
            return typeof (this.region) === "string" ? this.region : "";
        },
        allIndicesOptions(): Array<Config.SelectOption> {
            let rawList: Array<Config.SelectOption> = [];
            
            Config.CountryByRegion.forEach((val:Array<Config.SelectOption>)=>{
                rawList = [...rawList, ...val];
            });
            rawList = rawList.sort((x, y)=>{
                return x.label.localeCompare(y.label);
            });
            return rawList;
        },
        timePeriod(): number {
            return Config.GetNumberOfMonths(this.timePeriodSelected.id);
        },
        chartFrequency(): string {
            return this.timePeriod <1 ? 'YEARLY' : this.timePeriod <= 3 ? 'DAILY' : 'MONTHLY';
        },
        selectedCountryCode(): string {
            return Config.CountryCodeById.get(this.countrySelected.id) || '';
        },
        chartSize():number {
            return  this.isSmallComponent ? 215 : 420;
        },
        isSmallComponent():boolean {
            return this.componentSize?.toLowerCase() === 'small';
        },
        mdsComponentSize(): string {
            return this.isSmallComponent ? 'small' : 'medium';
        },
        headerTag(): string {
            return this.isSmallComponent ? 'h3' : 'h2';
        },
        companyValuationLink(): Location {
            return {
                name:'investments-screeners',
                params: {
                    id: this.selectedCountryCode+'-stock',
                    type: 'ST'
                }
            };  
        }
    },
    watch:{
        region() {
            this.countryOptions= this.region ? JSON.parse(JSON.stringify(Config.CountryByRegion.get(this.region))) : this.allIndicesOptions as Array<Config.SelectOption>;
            this.countrySelected = this.countryOptions.find(t=>t.selected) || this.countryOptions[0];
        }
    },
    created() {
        this.countryOptions= this.region ? JSON.parse(JSON.stringify(Config.CountryByRegion.get(this.region))) : this.allIndicesOptions as Array<Config.SelectOption>;
            
        this.timePeriodSelected = this.timePeriodOptions.find(t=>t.selected) || this.timePeriodOptions[0];
        this.countrySelected = this.countryOptions.find(t=>t.selected) || this.countryOptions[0];
    },
    data() {
        return {
            uId: nanoid(6),
            timePeriodToggle: false,
            timePeriodOptions: JSON.parse(JSON.stringify(Config.MarketsTimePeriod)) as Array<Config.SelectOption>,
            timePeriodSelected: {} as Config.SelectOption,
            countryToggle: false,
            countryOptions: [] as Array<Config.SelectOption>,
            countrySelected: {} as Config.SelectOption,
            isMethodologyModalActive: false,
            headerTooltipToggle: false
        };
    },
    methods:{
        onTimePeriodItemClick(menu: Config.SelectOption) {
            this.timePeriodSelected = Object.assign(menu);
            this.timePeriodToggle = false;
        },
        onCountryItemClick(menu: Config.SelectOption) {
            this.countrySelected = Object.assign(menu);
            this.countryToggle = false;
        }
    }
});
