
import Vue from 'vue';
import McaLineupTopStories from '@mca-components-ui/lineup-top-stories';
import McaAdvertContainer from '@mca-components-ui/advert-container';
import { Contentstack } from '@mca-components-ui/models';
import { AdvertPlacement } from '@/configs/adverts';
import { PLACEHOLDERS } from "~/configs/images";
import type { MCA } from '@mca-components-ui/types';

export default Vue.extend({
    components: {
        McaLineupTopStories,
        McaAdvertContainer
    },
    data() {
        return {
            PLACEHOLDERS
        };
    },
    computed: {
        topStories(): MCA.Models.Contentstack.ITopStories[] {
            return this.$store.state.lineup.topStories.map((story: { top_stories: any; }) => new Contentstack.TopStories(story.top_stories));
        },
        adverts(): Record<AdvertPlacement, string> {
            return this.$store.state.adverts.placements;
        },
        isDesktop(): boolean {
            return this.$store.getters['layout/windowWidth'] >= 1000;
        }
    },
    async fetch () {
        await this.$store.dispatch('lineup/getTopStoriesFromCS');
    }
});
