export class CacheService {
	
    private static caches: Map<string, Map<string, any>> = new Map();

    /**
	 * Default cache timeout value in seconds (3600 = 1 hour)
	 */
    public static DEFAULT_CACHE_EXPIRY = 3600;

    /**
	 * If item in specified cache doesn't exist - create one, or get it
	 * @param cacheId Unique cache group name
	 * @param memberId Unique item name
	 * @param fn Function to execute if not found in cache, uses return value as cache value
	 * @param expires Number of seconds until cache expires
	 * @return <T> The cache value of any type
	 */
    public static computeIfAbsent<T>(cacheId: string, memberId: string, fn: () => T, expires: number = CacheService.DEFAULT_CACHE_EXPIRY): T {
		
		 if (!this.caches.has(cacheId)) {
			 this.caches.set(cacheId, new Map());
		 }

		 const cache = this.caches.get(cacheId) as Map<string, T>;

		 if (!cache.has(memberId)) {
			 const value: T = fn.call(null);
			 CacheService.set(cacheId, memberId, value, expires);
		 }

		 return cache.get(memberId) as T;
    }

    public static cacheFetchRequest<T>(cacheId: string, memberId: string, request: Request, expires: number = CacheService.DEFAULT_CACHE_EXPIRY): Promise<T> {
		
		 if (!this.caches.has(cacheId)) {
			 this.caches.set(cacheId, new Map());
		 }

		 const cache = this.caches.get(cacheId) as Map<string, T>;

		 if (!cache.has(memberId)) {

			 return fetch(request)
				 .then((response) => {
					 if(response.ok) {
						 return response.json();
					 } 
					 else {
						 return Promise.reject(response.statusText);
					 }
				 }).then((response: T) => {
					 CacheService.set<T>(cacheId, memberId, response, expires);

					 return response;
				 });
		 }

		 return Promise.resolve(CacheService.get<T>(cacheId, memberId));
    }

    public static set<T>(cacheId: string, memberId: string, value: T, expires: number = CacheService.DEFAULT_CACHE_EXPIRY): void {
		
		 if (!this.caches.has(cacheId)) {
			 this.caches.set(cacheId, new Map());
		 }

		 const cache = this.caches.get(cacheId) as Map<string, T>;

		 cache.set(memberId, value);

		 setTimeout(() => {
			 cache.delete(memberId);
		 }, expires * 1000);
    }

    public static clear(cacheId: string, memberId: string): void {
		 const cache = this.caches.get(cacheId);

		 if(cache) {
			 cache.delete(memberId);
		 }
    }

    public static getAll(): Map<string, Map<string, any>> {
		 return this.caches;
    }

    public static getGroup(cacheId: string): Map<string, any> | undefined {
		 return this.caches.get(cacheId);
    }

    public static get<T>(cacheId: string, memberId: string): T {

		 const cacheGroup = this.caches.get(cacheId);

		 if (!cacheGroup) {
			 throw new CacheNotFoundError(`Cache group not found ${cacheId}`);
		 }

		 return cacheGroup.get(memberId);
    }
}

export default CacheService;

export class CacheNotFoundError extends Error {
    constructor(err: string) {
        super(err);
    }
}

export interface CacheService {
    /**
     * Create cache if not exist
     */
    computeIfAbsent<T>(cacheId: string, memberId: string, fn: () => any): T;
    cacheFetchRequest<T>(cacheId: string, memberId: string, request: Request, expires: number): Promise<T>;
}